/* eslint-disable no-new */
import { Wrapper, DatabaseSearchContext, MapDot } from "nystem-components";
import { useEffect, useRef, useContext } from "react";
import "./view.css";
import { Map, View } from "ol/index.js";
import { OSM } from "ol/source.js";
import { Tile as TileLayer } from "ol/layer.js";
import { useGeographic } from "ol/proj.js";
import "ol/ol.css";
import app from "nystem";

// eslint-disable-next-line react-hooks/rules-of-hooks
useGeographic();

const MapPoints = ({ model, view }) => {
  const mapRef = useRef();
  const { search } = useContext(DatabaseSearchContext);
  const value = (search && search.data) || [];

  useEffect(() => {
    const target = mapRef.current;
    let mapBaseField = false;
    if (model.mapBaseField)
      mapBaseField = view.baseView.getValue(model.mapBaseField);

    let {
      coordinate = mapBaseField || [13.012, 55.587],
      zoom = mapBaseField?.zoom || 8,
    } = {};

    const tileLayer = new TileLayer({
      source: new OSM({
        wrapX: false,
      }),
    });

    const map = new Map({
      layers: [tileLayer],
      view: new View({ center: mapBaseField?.coordinate || coordinate, zoom }),
      target,
    });

    map.on("moveends", (e) => {
      zoom = map.getView().getZoom();
      if (model.areaType) coordinate = map.getView().getCenter();

      view.setValue({
        path: model.id,
        value: { coordinate, zoom },
      });
    });

    let mapsDevicePos;
    const start = async () => {
      const { items } = await app().event("stylePoints", {
        items: value
          .map((item) => ({
            coordinate: item[model.id]?.coordinate,
            ...item,
          }))
          .filter(({ coordinate }) => coordinate),
      });

      const extent = [];
      items.forEach(({ coordinate }) => {
        // eslint-disable-next-line prefer-destructuring
        if (!extent[0] || coordinate[0] < extent[0]) extent[0] = coordinate[0];
        // eslint-disable-next-line prefer-destructuring
        if (!extent[2] || coordinate[0] > extent[2]) extent[2] = coordinate[0];
        // eslint-disable-next-line prefer-destructuring
        if (!extent[1] || coordinate[1] < extent[1]) extent[1] = coordinate[1];
        // eslint-disable-next-line prefer-destructuring
        if (!extent[3] || coordinate[1] > extent[3]) extent[3] = coordinate[1];
      });

      map.getView().fit(extent, map.getSize());
      const zoom = map.getView().getZoom();
      map.getView().setZoom(zoom - 0.2);
      MapDot({
        type: "default",
        points: items,
        map,
        tileLayer,
        onClickType: "selected",
        onClick: (pos) => {
          view.event("mapClick", value[pos]);
        },
      });

      if (model.locationMarker) {
        const style = view.baseView
          .getValue("style")
          .find((item) => item.state === "cursor");

        const [feature] = MapDot({
          type: "pulse",
          points: { ...style, coordinate: [13.012, 55.587] },
          map,
          tileLayer,
        });

        mapsDevicePos = ({ longitude, latitude }) => {
          if (!longitude) return;
          feature.getGeometry().setCoordinates([longitude, latitude]);
        };

        app().on("mapsDevicePos", mapsDevicePos);
        app().event("mapsDevicePos");
      }
    };
    start();

    return () => {
      if (model.locationMarker) app().off("mapsDevicePos", mapsDevicePos);
      target.innerHTML = "";
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return <Wrapper className={model.className} ref={mapRef}></Wrapper>;
};

export default MapPoints;
/*
var coordMin = ol.proj.fromLonLat([148.77638,-34.491728], 'EPSG:3857');
var coordMax = ol.proj.fromLonLat([148.77896,-34.492302], 'EPSG:3857');
var extent=[coordMin[0],coordMin[1],coordMax[0],coordMax[1]];
map.getView().fit(extent,map.getSize());
*/
