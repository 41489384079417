import React from "react";
import { Wrapper } from "nystem-components";

const TextReplace = ({ model, value }) => {
  const { find = "", replace = "", className, fallback = "" } = model;
  value = (value || fallback).replace(new RegExp(find, ""), replace);
  if (model.hideIfEmpty && !value) return null;

  return (
    <Wrapper
      className={className}
      renderAs={model.renderAs}
      translate={model.translate}
      title={value}
    >
      {value}
    </Wrapper>
  );
};
export default TextReplace;
