import React from "react";
import { Wrapper, ContentTypeRender } from "nystem-components";

const ViewButtonMapLink = ({ model, view, path }) => {
  const { className, item, fields } = model;
  const value = fields.map(({ id }) => view.getValue(id));
  const pos = value.join(",");
  // const href = `https://maps.google.com/maps/search/?q=${pos}&ll=${pos}&z=9`;
  const href = `https://maps.google.com/maps/search/?api=1&query=${pos}`;
  return (
    <Wrapper
      className={className}
      rel="noreferrer"
      renderAs="a"
      href={href}
      target="_blank"
      aria-label="Map link"
    >
      <ContentTypeRender path={path} items={item} />
    </Wrapper>
  );
};
export default ViewButtonMapLink;
