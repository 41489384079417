import React, { useEffect } from "react";
import { Wrapper } from "nystem-components";
import app from "nystem";

const getDistance = ([lat1, lon1], [lat2, lon2], unit = "K") => {
  const radlat1 = (Math.PI * lat1) / 180;
  const radlat2 = (Math.PI * lat2) / 180;

  const theta = lon1 - lon2;
  const radtheta = (Math.PI * theta) / 180;

  let dist =
    Math.sin(radlat1) * Math.sin(radlat2) +
    Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);

  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;

  if (unit === "K") dist *= 1.609344;
  if (unit === "N") dist *= 0.8684;

  return dist;
};

const addSearch = async ({ lat, long }) => {
  const res = await fetch(
    `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${long}`
  );
  const result = await res.json();
  const { address, lat: rlat, lon: rlong } = result;

  app().database.location.save({
    role: "super",
    data: {
      ...address,
      lat: parseFloat(rlat),
      long: parseFloat(rlong),
      _id: `${rlat}_${rlong}`,
    },
  });
};

const ViewButtonMapDistance = ({ model, view }) => {
  const {
    className,
    fieldId,
    latId,
    longId,
    baseLatId,
    baseLongId,
    fieldType = "distance",
  } = model;

  const distance = getDistance(
    [view.getValue(latId), view.getValue(longId)],
    [
      view.baseView.getValue(baseLatId || latId),
      view.baseView.getValue(baseLongId || longId),
    ]
  );

  useEffect(() => {
    if (
      fieldType === "saveLocation" &&
      !view.baseView.baseView.value.location &&
      distance < 0.2
    ) {
      app().database.mygps.save({
        fields: true,
        data: {
          _id: view.baseView.baseView.value._id,
          location: view.value._id,
        },
      });
    }
    if (!distance || fieldType !== "saveDistance" || distance < 0.2) return;

    const timer = setTimeout(() => {
      addSearch({
        lat: parseFloat(view.baseView.getValue(baseLatId || latId)),
        long: parseFloat(view.baseView.getValue(baseLongId || longId)),
      });
    }, 100);

    return () => {
      clearTimeout(timer);
    };
  }, [baseLatId, baseLongId, distance, fieldType, latId, longId, view]);

  if (fieldType === "saveDistance" || fieldType === "saveLocation") return null;

  const byType = {
    distance: () => distance.toFixed(2),
    energy: () => {
      const from = view.getValue(fieldId);
      const to = view.baseView.getValue(fieldId);
      if (!from || !to) return "-";

      return (((from - to) / distance) * 1000).toFixed(0);
    },
    speed: () =>
      (
        distance /
        ((view.getValue(fieldId) - view.baseView.getValue(fieldId)) /
          (1000 * 60 * 60))
      ).toFixed(0),
  };

  return <Wrapper className={className}>{byType[fieldType]()}</Wrapper>;
};
export default ViewButtonMapDistance;
// https://nominatim.openstreetmap.org/reverse?format=json&lat=55.9257&lon=13.8027
