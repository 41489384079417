/* eslint-disable no-new */
import { Wrapper, ContentTypeRender } from "nystem-components";
import { useEffect, useState } from "react";

const ConditionalDevicePos = ({ model, path }) => {
  const [access, setAccess] = useState(false);
  const { className, renderAs, item = [], noaccess = [] } = model;

  useEffect(() => {
    if (!navigator?.permissions) return;

    navigator.permissions.query({ name: "geolocation" }).then(({ state }) => {
      setAccess(state === "granted");
    });
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model]);

  return (
    <Wrapper className={className} renderAs={renderAs}>
      <ContentTypeRender path={path} items={access ? item : noaccess} />
    </Wrapper>
  );
};

export default ConditionalDevicePos;
