import { useContext } from "react";
import { DatabaseSearchContext } from "nystem-components";

const ViewListSearchToValue = ({ model, view }) => {
  const { search } = useContext(DatabaseSearchContext);

  const value = (search && search.data) || [];
  const { map, last } = model;
  const pos = last ? value.length - 1 : model.pos;
  const val = value[pos];

  if (!val) return null;

  const target = model.baseView ? view.baseView : view;

  map.forEach(([from, to]) => {
    if (val[from] !== view.value[to])
      target.setValue({ path: to, value: val[from] });
  });

  return null;
};
export default ViewListSearchToValue;
