import React, { useContext } from "react";
import app from "nystem";
import { Wrapper, DatabaseSearchContext } from "nystem-components";

const byType = {
  date: (from, to) => {
    if (!from || !to) return 0;

    const delta = parseInt(to, 10) - parseInt(from, 10);
    let out = " ";
    let minit = delta / 1000 / 60;

    const days = Math.trunc(minit / 60 / 24);
    if (days) out = `${days}d `;
    minit -= days * 60 * 24;

    const hours = Math.trunc(minit / 60);
    if (hours) out += `${hours}h `;
    minit -= hours * 60;

    if (minit) out += `${Math.trunc(minit)}m`;
    return out;
  },
  int: (from, to) => {
    if (!from || !to) return 0;

    return parseInt(to, 10) - parseInt(from, 10);
  },
};

const ReferenceDiff = ({ model }) => {
  const { className, renderAs, field, fieldType } = model;

  const { search } = useContext(DatabaseSearchContext);

  const value = (search && search.data) || [];
  if (!value[0]) return null;

  const result = byType[fieldType](
    value[value.length - 1][field],
    value[0][field]
  );

  return (
    <Wrapper renderAs={renderAs} className={className}>
      {app().t(value ? result : "")}
    </Wrapper>
  );
};

export default ReferenceDiff;
