import app from "nystem";

const getBatterySize = (date) =>
  app().settings.battSize.reduce(
    (prev, { from, size }) => (from < date ? size : prev),
    21.6
  );

const FloatSetChargePower = ({ model, view, setValue, value }) => {
  const { timeId, socId, sohId } = model;

  const diff = (id) => view.getValue(id) - view.baseView.getValue(id);
  const soh =
    (view.getValue(sohId) / 10000) * getBatterySize(view.value._crdate);

  if (!value) {
    const newVal = (diff(socId) * soh) / (diff(timeId) / 1000 / 60 / 60);
    if (newVal) setValue(newVal);
  }
  return null;
};
export default FloatSetChargePower;
