import React, { useState, useEffect } from "react";
import { Wrapper, ContentTypeRender } from "nystem-components";

const DateHideOld = ({ model, path = "", value }) => {
  const [count, setRerender] = useState(0);

  const { className, renderAs, item, deltams } = model;
  const delta = value + deltams - Date.now();

  useEffect(() => {
    if (delta < 0) return;

    const timer = setTimeout(() => {
      setRerender(count + 1);
    }, delta);

    return () => {
      clearTimeout(timer);
    };
  }, [count, delta]);

  if (delta < 0) return null;

  return (
    <Wrapper className={className} renderAs={renderAs}>
      <ContentTypeRender path={path.replace(model.id, "")} items={item} />
    </Wrapper>
  );
};

export default DateHideOld;
