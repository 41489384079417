/* eslint-disable no-new */
import { Wrapper, ContentTypeRender } from "nystem-components";
import app from "nystem";
import { useEffect, useState } from "react";

const MapIndicator = ({ model, path = "" }) => {
  const [type, setType] = useState("itemFail");

  useEffect(() => {
    const { limit } = model;
    const update = ({ error, timestamp, accuracy }) => {
      setType(
        error || !timestamp
          ? "itemFail"
          : accuracy > limit
          ? "itemToFar"
          : "item"
      );
    };

    app().on("mapsDevicePos", update);
    app().event("mapsDevicePos");
    return () => {
      app().off("mapsDevicePos", update);
    };
  }, [model]);

  return (
    <Wrapper className={model.className}>
      <ContentTypeRender
        path={path.replace(model.id, "")}
        items={model[type]}
      />
    </Wrapper>
  );
};

export default MapIndicator;
