import { TextView } from "nystem-components";
import app from "nystem";

const IntlNfSE = new Intl.NumberFormat("no-NO", {
  style: "currency",
  currency: "SEK",
});
const Intl_SEK = (v) => IntlNfSE.format(v).replace("SEK", "");

function outputFloat({ value, model }) {
  if (!value || !model) return value;
  if (model.money) return Intl_SEK(value);

  if (model.tofixed === undefined) return value;
  value = parseFloat(value).toFixed(model.tofixed);
  return app().settings.lang === "sv" ? value.replace(".", ",") : value;
}

const FloatView = (props) => {
  return (
    <TextView
      {...props}
      value={outputFloat(props) || props.model.fallback || "0"}
    />
  );
};
export default FloatView;
