const FloatSetPower = ({ model, view, setValue, value }) => {
  const { currentId, voltId } = model;

  let power =
    (parseFloat(view.getValue(currentId)) * parseFloat(view.getValue(voltId))) /
    1000;

  if (model.abs) power = Math.abs(power);
  if (!value) setValue(power);

  return null;
};
export default FloatSetPower;
