import React, { useEffect, useState } from "react";
import app from "nystem";
import { Wrapper } from "nystem-components";

function outputFloat({ value, model }) {
  if (!value || !model) return value;
  value = parseFloat(value).toFixed(model.tofixed || 0);
  return app().settings.lang === "sv" ? value.replace(".", ",") : value;
}

const getBatterySize = (date) =>
  app().settings.battSize.reduce(
    (prev, { from, size }) => (from < date ? size : prev),
    21.6
  );

const FloatEnergy = ({ model, view, value }) => {
  const { className, renderAs, fromId, fieldType } = model;
  const [val, setVal] = useState();

  const viewByType = {
    view,
    baseView: view.baseView,
    baseViewBaseView: view.baseView?.baseView,
    baseViewBaseViewBaseView: view.baseView?.baseView?.baseView,
    baseViewBaseViewBaseViewBaseView:
      view.baseView?.baseView?.baseView?.baseView,
  };
  const ev = viewByType[fromId]?.value?._id;
  if (fieldType === "fetchDistance") value -= view.baseView.value[model.id];

  useEffect(() => {
    const getData = ({ data }) => ({ data: { ...data, [fieldType]: value } });

    app().on(ev, getData);

    app().event(ev, { data: {} });

    return () => {
      app().off(ev, getData);
    };
  }, [ev, fieldType, value]);

  useEffect(() => {
    if (fieldType.startsWith("fetch")) return;

    const evByType = {
      showEnergy: ({ data }) => {
        if (!data.fetchEnergy || !data.showEnergy) return;

        const batt = getBatterySize(view.value._crdate) / 100;
        const diff = Math.abs(data.fetchEnergy - data.showEnergy);

        setVal(diff * batt);
      },
      showEnergyRecd: ({ data }) => {
        if (!data.fetchEnergyRecd || !data.showEnergyRecd) return;

        setVal(data.fetchEnergyRecd - data.showEnergyRecd);
      },
      showEnergyUsed: ({ data }) => {
        if (!data.fetchEnergyUsed || !data.showEnergyUsed) return;

        setVal(data.showEnergyUsed - data.fetchEnergyUsed);
      },
      showEnergyPercent: ({ data }) => {
        if (!data.fetchEnergy || !data.showEnergyPercent) return;

        const diff = Math.abs(data.fetchEnergy - data.showEnergy);
        setVal(diff);
      },
      showEnergyPerkm: ({ data }) => {
        if (!data.fetchEnergy || !data.showEnergyPerkm || !data.fetchDistance)
          return;

        const batt = getBatterySize(view.value._crdate) * 10;
        const diff = Math.abs(data.fetchEnergy - data.showEnergy);
        setVal((diff * batt) / -data.fetchDistance);
      },
    };

    app().on(ev, -100, evByType[fieldType]);
    app().event(ev, { data: {} });

    return () => {
      app().off(ev, evByType[fieldType]);
    };
  }, [ev, fieldType, view.value._crdate]);

  if (fieldType.startsWith("fetch")) return null;

  return (
    <Wrapper renderAs={renderAs} className={className}>
      {outputFloat({ model, value: val })}
    </Wrapper>
  );
};

export default FloatEnergy;
