import React, { useEffect, useState } from "react";
import { TextView } from "nystem-components";

const IntParktime = ({ value, view, model }) => {
  const [out, setOut] = useState(0);

  if (!value) value = "0";

  useEffect(() => {
    const update = () => {
      const intVal = parseInt(value, 10);
      const delta = Date.now() - view.getValue("_crdate");
      let out = " ";
      let minit = delta / 1000 / 60 + intVal / 60;

      const days = Math.trunc(minit / 60 / 24);
      if (days) out = `${days}d `;
      minit -= days * 60 * 24;

      const hours = Math.trunc(minit / 60);
      if (hours) out += `${hours}h `;
      minit -= hours * 60;

      if (minit) out += `${Math.trunc(minit)}m`;
      setOut(out);
    };
    setInterval(update, 10000);
    update();

    return () => {
      clearInterval(update);
    };
  }, [value, view]);

  return <TextView model={model} view={view} value={out} />;
};
export default IntParktime;
