/* eslint-disable no-new */
import { Wrapper, InputWrapper, UseValidator, MapDot } from "nystem-components";
import app from "nystem";
import { useEffect, useRef, useState } from "react";
import { Point } from "ol/geom.js";
import { Map, View } from "ol/index.js";
import { OSM } from "ol/source.js";
import { Tile as TileLayer } from "ol/layer.js";
import { useGeographic } from "ol/proj.js";
import "ol/ol.css";

// eslint-disable-next-line react-hooks/rules-of-hooks
useGeographic();

const MapView = ({ value, model }) => {
  const mapRef = useRef();

  useEffect(() => {
    const target = mapRef.current;

    const { coordinate = [13.012, 55.587], zoom = 11 } = value || {};

    const point = new Point(coordinate);
    const tileLayer = new TileLayer({
      source: new OSM({
        wrapX: false,
      }),
    });

    const map = new Map({
      layers: [tileLayer],
      view: new View({ center: coordinate, zoom: 11 }),
      target,
    });

    MapDot({
      type: "default",
      points: { coordinate: point, color: "blue", shape: "dot" },
      map,
      tileLayer,
    });

    return () => {
      target.innerHTML = "";
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Wrapper ref={mapRef} className={model.className}></Wrapper>;
};

export default MapView;
