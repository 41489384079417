import React from "react";
import { Wrapper, ContentTypeRender } from "nystem-components";

const GpsLink = ({ model, value, path }) => {
  const { className, item } = model;
  if (!value) return null;
  const pos = value.join("%2C");
  const link = model.alt
    ? `https://www.google.com/maps/search/?api=1&query=${pos}`
    : `https://www.google.com/maps/place/${value.join("+")}/@${value.join(
        ","
      )},10z`;

  return (
    <Wrapper className={className} renderAs="a" href={link} target="_blank">
      <ContentTypeRender path={path} items={item} />
    </Wrapper>
  );
};
export default GpsLink;
// href={`https://www.google.com/maps/search/?q=${pos}&ll=${pos}&z=9`}
