import React, { useEffect, useState, useRef } from "react";
import app from "nystem";
import { Wrapper, ContentTypeRender } from "nystem-components";

const GraphPopup = ({ model, view, path }) => {
  const [query, setQuery] = useState(false);
  const href = useRef();

  useEffect(() => {
    let isSetClick = false;

    const graphEvent = ({ type, x, y, pos, data = {} }) => {
      if (view.id !== data.id) return;

      if (type === "click") {
        isSetClick = !isSetClick;
        setQuery(isSetClick ? { type, pos, data, x, y } : false);
      }

      if (type === "over") setQuery({ type, pos, data, x, y });
      if (type === "out" && !isSetClick) setQuery(false);
    };

    app().on("graphEvent", graphEvent);

    return () => {
      app().off("graphEvent", graphEvent);
    };
  }, [view]);

  if (!query) return <Wrapper ref={href} className={"fixed"} />;

  return (
    <Wrapper
      className={["absolute z-10 bg-white p-3 text-gray-500", model.className]}
      style={{
        left: `${query.x + query.pos.cx + 10}px`,
        top: `${query.y + query.pos.cy + 10}px`,
      }}
    >
      <ContentTypeRender path={path} items={model.item} />
    </Wrapper>
  );
};
export default GraphPopup;
