import React from "react";
import app from "nystem";
import { Wrapper } from "nystem-components";

const IntDiff = ({ model, view, value }) => {
  const { className, renderAs, id, suffix = "" } = model;

  const base = view.baseView.value[id];

  const result =
    (!value && value !== 0) || (!base && base !== 0)
      ? 0
      : parseInt(base, 10) - parseInt(value, 10);

  return (
    <Wrapper renderAs={renderAs} className={className}>
      {app().t(value ? `${result} ${suffix}` : "")}
    </Wrapper>
  );
};

export default IntDiff;
