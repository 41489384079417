/* eslint-disable no-new */
import { Wrapper, ContentTypeRender } from "nystem-components";
import app from "nystem";
import { useEffect, useState } from "react";

const MapDistance = ({ model, value, path = "", view }) => {
  const [isOutside, setIsOutside] = useState(true);

  useEffect(() => {
    const val = value;
    const update = async (data) => {
      const { latitude: lat1, longitude: lng1 } = data;
      const [lng2, lat2] = val.coordinate;
      if (!lat1 || !lng1 || !lat2 || !lng2) return;

      const { distance } = app().mapAzimuth(lat1, lng1, lat2, lng2);
      if (model.limitField) {
        const { data: limitField } = await app().database[model.limitField].get(
          { id: view.value[model.limitField] }
        );

        setIsOutside(distance > (limitField?.limit || model.limit));
      } else setIsOutside(distance > model.limit);
    };

    app().on("mapsDevicePos", update);
    app().event("mapsDevicePos");
    return () => {
      app().off("mapsDevicePos", update);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model]);

  const { className, outside = [], item = [] } = model;
  return (
    <Wrapper className={className}>
      <ContentTypeRender
        path={path.replace(model.id, "")}
        items={isOutside ? outside : item}
      />
    </Wrapper>
  );
};

export default MapDistance;
