import React from "react";
import { Wrapper } from "nystem-components";

const IntBaseView = ({ model, view }) => {
  const viewByType = {
    view,
    baseView: view.baseView,
    baseViewBaseView: view.baseView?.baseView,
    baseViewBaseViewBaseView: view.baseView?.baseView?.baseView,
    baseViewBaseViewBaseViewBaseView:
      view.baseView?.baseView?.baseView?.baseView,
  };

  const { className, renderAs, translate, fallback } = model;

  const value = viewByType[model.fromView].getValue(model.id);

  return (
    <Wrapper
      className={className}
      renderAs={renderAs}
      translate={translate}
      title={value || fallback}
    >
      {value || fallback}
    </Wrapper>
  );
};
export default IntBaseView;
