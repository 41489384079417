import React from "react";
import {
  Inserter,
  Wrapper,
  SessionRole,
  DragAndDropContext,
  ExtensionView,
  RouterScrollpos,
} from "nystem-components";
import "../../../index.css";
import "./index.css";

const ExtensionStyle = () => (
  <style
    dangerouslySetInnerHTML={{
      __html: `
   body {   
     min-width: 600px; 
     min-height: 600px;
   }
`,
    }}
  />
);

const Index = () => (
  <DragAndDropContext>
    <ExtensionView>
      <ExtensionStyle />
    </ExtensionView>
    <Wrapper className="mb-10 text-gray-100">
      <header className="items-top fixed top-0 z-20 flex w-full bg-black">
        <Inserter match="*" source="/control/nav" />
      </header>
      <main className="h-full-minus mt-14">
        <Inserter source="/my12v/extension" match="/background.html" />
        <SessionRole userrole="logged-out">
          <Inserter className="px-3" source="/adminUser/login" match="*" />
        </SessionRole>
        <SessionRole userrole="logged-in" className="text-base">
          <Inserter match="/" source="/control/front" />
          <Inserter match="/popup.html" source="/control/front" />
          <Inserter match="/all" source="/control/all" />
          <Inserter match="/ovmsstatus/*" />
          <Inserter match="/mer/*" />
          <Inserter match="/mycharge/*" />
          <Inserter match="/location/*" />
          <Inserter match="/mysoc/*" />
          <Inserter match="/my12v/*" />
          <Inserter match="/mygps/*" />
          <Inserter match="/mytemp/*" />
          <Inserter match="/myenv/*" />
          <Inserter match="/datadump/*" />
          <Inserter match="/unit/*" className="max-w-sm px-3" />
          <Inserter match="/touchscreen/*" className="max-w-sm px-3" />
          <Inserter match="/event/*" className="max-w-sm px-3" />
          <Inserter match="/device/*" className="max-w-sm px-3" />
          <Inserter match="/data" source="/mycharge/list" />
          <Inserter match="/chargerstate/*" />
          <Inserter match="/location" source="/location/indicatormsg" />
        </SessionRole>
      </main>
    </Wrapper>
    <RouterScrollpos />
  </DragAndDropContext>
);

export default Index;
