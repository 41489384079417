import { useEffect } from "react";
import app from "nystem";

const getCoords = ({ from, to, count }) => {
  const delta = to - from;
  return new Array(count).map((und, index) => parseInt(index * delta, 10));
};

const GraphLabels = ({ model, view }) => {
  useEffect(() => {
    const { fieldType: type, countX, countY, fieldIdY } = model;
    const graphEventPre = (query) => {
      if (query.type !== "labels") return;

      const { top, bottom, left, right } = query;

      return {
        ...query,
        x: getCoords({ from: top, to: bottom, count: countX }).map((pos) => ({
          pos,
        })),
        y: getCoords({ from: left, to: right, count: countY }).map((y) => ({
          y,
        })),
      };
    };

    const graphEventPost = (query) => {
      if (query.type !== "labels") return;

      const { top, bottom, left, right } = query;

      return {
        ...query,
        x: getCoords({ from: top, to: bottom, count: countX }),
        y: getCoords({ from: left, to: right, count: countY }),
      };
    };

    app().on("graphEvent", 10, graphEventPre);
    app().on("graphEvent", -10, graphEventPost);
    app().event("graphEvent", { type: "labels" });

    return () => {
      app().off("graphEvent", graphEventPre);
      app().off("graphEvent", graphEventPost);
    };
  }, [model, view]);

  return null;
};
export default GraphLabels;
