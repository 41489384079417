import React from "react";
import app from "nystem";
import { Wrapper, ContentTypeRender } from "nystem-components";

const getBatterySize = (date) =>
  app().settings.battSize.reduce(
    (prev, { from, value }) => (from < date ? value : prev),
    21.6
  );

const ConditionalChargePower = ({ model, view, path }) => {
  const { className, timeId, socId, sohId } = model;

  const getSum = (view) => {
    const diff = (id) => view.getValue(id) - view.baseView.getValue(id);
    if (diff < 0) console.log("miniud");
    const soh =
      (view.getValue(sohId) / 10000) * getBatterySize(view.value._crdate);
    return (diff(socId) * soh) / (diff(timeId) / 1000 / 60 / 60);
  };
  let sum = getSum(view);
  sum = sum < 3 ? sum.toFixed(1) : sum.toFixed(0);
  let sum2 = getSum(view.baseView);
  sum2 = sum2 < 3 ? sum2.toFixed(1) : sum2.toFixed(0);

  if (sum === sum2) return null;

  return (
    <Wrapper className={className}>
      <ContentTypeRender path={path} items={model.item} />
    </Wrapper>
  );
};
export default ConditionalChargePower;
