/* eslint-disable no-new */
import { Wrapper } from "nystem-components";
import app from "nystem";
import { useEffect, useState } from "react";

const MapRedirect = ({ model, view, path }) => {
  const [val, setVal] = useState();

  useEffect(() => {
    const insertVal = (val, data) =>
      val &&
      val.replace(/\{([a-z_.0-9]+)\}/gim, (str, p1) => {
        let val = "";
        if (p1 === "_language") val = app().settings.lang;
        else if (p1 === "id") val = view.id;
        else if (p1.indexOf("params.") === 0)
          val = view.params[p1.replace("params.", "")];
        else if (p1.indexOf("baseView.") === 0)
          val = view.baseView.getValue(
            p1.replace("baseView.", "").replace("..", path)
          );
        else if (p1.indexOf("view.") === 0)
          val = view.getValue(p1.replace("view.", "").replace("..", path));
        else val = data[p1.replace("..", path)];
        return val;
      });
    const doEvent = (data) => {
      console.log(insertVal(model.to, data));
      window.history.pushState({}, "", insertVal(model.to, data));
    };

    view.on(model.event, doEvent);
    return () => {
      view.off(model.event, doEvent);
    };
  }, [model, path, view]);

  return null;
};

export default MapRedirect;
