import React, { useContext } from "react";
import { DatabaseSearchContext, ContentTypeView } from "nystem-components";

const ViewListPos = ({ model, view }) => {
  const { search } = useContext(DatabaseSearchContext);

  const value = (search && search.data) || [];
  const { className, last } = model;
  const pos = last ? value.length - 1 : model.pos;
  const item = value[pos];

  if (!item) return null;

  const id = ["number", "string"].includes(typeof item) ? item : item._id;

  const settings = {
    view: model,
    contentType: view.contentType,
    id,
    noForm: true,
    baseView: view,
    params: view.params,
    className,
  };

  return <ContentTypeView {...settings} />;
};
export default ViewListPos;
