/* eslint-disable no-new */
import app from "nystem";
import { useEffect } from "react";

let pos = [55.5974656, 13.0023424];

const MapWeatherFetcher = ({ model, value, view }) => {
  useEffect(() => {
    let val = value;
    const setAt = ({ id, value }) => {
      if (id === model.id) val = value[id];
    };
    const mapsDevicePos = (data) => {
      const { latitude: lat, longitude: lng } = data;
      pos = [lng, lat];
    };

    if (model.fromPoint) view.on("change", setAt);
    else {
      app().on("mapsDevicePos", mapsDevicePos);
      app().event("mapsDevicePos");
    }

    const type = "weather"; // weather, forecast
    const weatherUpdate = async () => {
      const loc = model.fromPoint ? val?.coordinate || pos : pos;
      const response = await fetch(
        `https://api.openweathermap.org/data/2.5/${type}?lat=${loc[1]}&lon=${loc[0]}&units=metric&appid=fedf33dfd3ad1d89e2fc4a5e641ac6b6`
      );

      return { weatherData: await response.json() };
    };
    view.on(`weatherUpdate`, weatherUpdate);

    const weatherTimer = setInterval(() => view.event(`weatherUpdate`), 10000);
    view.event(`weatherUpdate`);

    return () => {
      view.off("change", setAt);
      view.off(`weatherUpdate`, weatherUpdate);

      clearInterval(weatherTimer);
      app().off("mapsDevicePos", mapsDevicePos);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model, view]);

  return null;
};

export default MapWeatherFetcher;
