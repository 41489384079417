import { useEffect } from "react";
import app from "nystem";

const geoErrors = {
  1: "Request for Geolocation denied.",
  2: "Location information is unavailable.",
  3: "Get location timed out.",
  4: "Get location unavailable.",
};

const MapDevicePos = ({ model }) => {
  useEffect(() => {
    let current;
    const setCurrent = (data) => {
      if (data.code)
        current = {
          error: geoErrors[data.code],
          update: true,
        };
      else if (data.coords) {
        const {
          accuracy,
          altitude,
          altitudeAccuracy,
          heading,
          latitude,
          longitude,
          speed,
        } = data.coords;

        current = {
          ...current,
          accuracy,
          altitude,
          altitudeAccuracy,
          heading,
          latitude,
          longitude,
          speed,
          timestamp: data.timestamp,
          update: true,
        };
      } else if (data.gamma) {
        const { alpha, gamma, webkitCompassHeading, beta } = data;

        current = {
          ...current,
          webkitCompassHeading,
          alpha,
          gamma,
          beta,
        };
      } else if (data.compass) {
        const { compass } = data;

        current = {
          ...current,
          compass,
        };
      }
      app().event("mapsDevicePos", current);
    };

    const start = async ({ first }) => {
      if (!navigator.geolocation) setCurrent({ code: 4 });
      else {
        const result = await navigator.permissions.query({
          name: "geolocation",
        });

        if (result.state === "granted" || result.state === "prompt") {
          navigator.geolocation.getCurrentPosition(setCurrent, setCurrent);
          navigator.geolocation.watchPosition(setCurrent);

          if (model.deviceorientation)
            window.addEventListener("deviceorientation", setCurrent, false);
        } else window.history.pushState({}, "", model.redirect);
      }
      if (typeof DeviceOrientationEvent.requestPermission === "function") {
        const permissionState =
          await DeviceOrientationEvent.requestPermission();
        if (permissionState === "granted")
          window.addEventListener("deviceorientation", setCurrent, false);
      }
    };
    start({ first: false });

    function startCompassListener(callback) {
      if (!window.DeviceOrientationEvent) {
        console.warn("DeviceOrientation API not available");
        return;
      }
      const absoluteListener = (e) => {
        if (!e.absolute || e.alpha == null || e.beta == null || e.gamma == null)
          return;
        let compass = -(e.alpha + (e.beta * e.gamma) / 90);
        compass -= Math.floor(compass / 360) * 360; // Wrap into range [0,360].
        window.removeEventListener("deviceorientation", webkitListener);
        callback(compass);
      };

      let webkitListener = (e) => {
        const compass = e.webkitCompassHeading;
        if (compass != null && !isNaN(compass)) {
          callback(compass);
          window.removeEventListener(
            "deviceorientationabsolute",
            absoluteListener
          );
        }
      };

      function addListeners() {
        // Add both listeners, and if either succeeds then remove the other one.
        window.addEventListener("deviceorientationabsolute", absoluteListener);
        window.addEventListener("deviceorientation", webkitListener);
      }

      if (typeof DeviceOrientationEvent.requestPermission === "function") {
        DeviceOrientationEvent.requestPermission().then((response) => {
          if (response === "granted") {
            addListeners();
          } else console.warn("Permission for DeviceMotionEvent not granted");
        });
      } else addListeners();
    }

    if (model.devicecompass)
      startCompassListener((compass) => {
        setCurrent({ compass });
      });

    const update = ({ error, timestamp }) => {
      if (error || timestamp) return;
      return { ...current, update: false };
    };

    app().on("mapsDevicePos", 1000, update);
    app().on("askMapsDevicePos", 1000, start);
    return () => {
      app().off("mapsDevicePos", update);
      app().off("askMapsDevicePos", 1000, start);
    };
  }, [model]);

  return null;
};

export default MapDevicePos;

// https://newnow.co/me-myself-and-i/ deviceorientation
// https://chrishewett.com/blog/device-orientation-test-page/
// https://medium.com/hackernoon/building-a-compass-web-app-c79fec31e080
