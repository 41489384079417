import React, { useEffect, useState } from "react";
import { Wrapper } from "nystem-components";
import app from "nystem";

const getDistance = ([lat1, lon1], [lat2, lon2], unit = "K") => {
  const radlat1 = (Math.PI * lat1) / 180;
  const radlat2 = (Math.PI * lat2) / 180;

  const theta = lon1 - lon2;
  const radtheta = (Math.PI * theta) / 180;

  let dist =
    Math.sin(radlat1) * Math.sin(radlat2) +
    Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);

  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;

  if (unit === "K") dist *= 1.609344;
  if (unit === "N") dist *= 0.8684;

  return dist;
};

const getValue = (value, path) =>
  path.split(".").reduce((val, key) => val && val[key], value);

const GpsDistance = ({ model, view, value }) => {
  const [distance, setDistance] = useState("");
  const { className, renderAs } = model;
  useEffect(() => {
    if (!value) return;

    const refView = getValue(view, model.viewPath);

    const update = async () => {
      const id = refView.getValue(model.field);
      if (`${view.value._id}` === `${id}`) {
        setDistance(0.0);
        return;
      }
      const { data } = await app().database[view.contentType].get({ id });
      if (data) setDistance(getDistance(data[model.id] || [], value || []));
    };

    update();
    let timer;
    const doUpdate = () => {
      timer = setTimeout(update, 10);
    };
    refView.on("change", doUpdate);
    return () => {
      refView.off("change", doUpdate);
      clearTimeout(timer);
    };
  }, [model, view, value]);

  return (
    <Wrapper className={className} renderAs={renderAs}>
      {distance || distance === 0.0 ? `${distance.toFixed(0)} km` : "-"}
    </Wrapper>
  );
};
export default GpsDistance;
