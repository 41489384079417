import { useEffect } from "react";
import app from "nystem";

const lines = {};
const queues = {};

const timeout = (timeout) =>
  new Promise((resolve) => setTimeout(() => resolve("timeout"), timeout));

const run = async (name) => {
  const line = lines[name];
  const { delay, timeOut } = line;

  const call = queues[name].shift();
  if (!call) return;

  line.running++;
  const status = await Promise.race([call(), timeout(timeOut)]);

  if (status === "timeout")
    console.error(`Stand in line timeout error for ${name}`);

  setTimeout(() => {
    line.running--;
    run(name);
  }, delay);
};

const defaultOptions = { timeOut: 120000, limit: 1, delay: 0, running: 0 };
const init = (name, options = {}) => {
  lines[name] = { ...defaultOptions, ...options };
  queues[name] = [];
};

const waitInLine = (name, callback) =>
  new Promise((resolve, reject) => {
    if (!queues[name]) {
      console.error(`Missing line ${name}`);
      return;
    }
    queues[name].push(async () => {
      try {
        const res = await callback();
        resolve(res);
      } catch (e) {
        reject(e);
      }
    });

    const { limit, running } = lines[name];
    if (limit > running) run(name);
  });

init("openstreetmap", { delay: 60 * 1000 });

const addSearch = (() => {
  return ({ lat, long }) => {
    waitInLine("openstreetmap", async () => {
      const res = await fetch(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${long}`
      );
      const result = await res.json();

      app().database.location.save({
        role: "super",
        data: { ...result.address, lat, long, _id: `${lat}_${long}` },
      });
    });
  };
})();

const ViewButtonMapAdress = ({ view }) => {
  const [lat, long] = view.id.split("_");

  useEffect(() => {
    if (view.value.city || !lat || !long) return;
    if (lat === "null" || lat === "undefined") return;
    if (long === "null" || long === "undefined") return;

    addSearch({ lat, long });
  }, [lat, long, view, view.value.city]);

  return null;
};
export default ViewButtonMapAdress;

/*
city: "Malmo"
city_district: "Innerstaden"
country: "Sweden"
country_code: "se"
county: "Skåne County"
house_number: "12"
municipality: "Malmö kommun"
postcode: "21158"
road: "Ronnebygatan"
suburb: "Västra Sorgenfri"

city,city_district,country,country_code,county,
house_number,municipality,postcode,road,suburb,

{road} {house_number} {suburb} {city} {county}
*/
