import React from "react";
import { TextView } from "nystem-components";

const IntPower = ({ value, view, model }) => {
  if (!value) value = "0";

  let power =
    (parseFloat(view.getValue(model.afield)) * parseFloat(value)) / 1000;

  if (model.abs) power = Math.abs(power);

  return (
    <TextView model={model} view={view} value={parseFloat(power).toFixed(1)} />
  );
};
export default IntPower;
