import React, { useEffect } from "react";
import { ContentTypeRender } from "nystem-components";

const ExtensionView = ({ invert, model = {}, path, children }) => {
  const inExtension = window.location.protocol === "chrome-extension:";

  useEffect(() => {
    if (!inExtension) return;
    document.body.classList.add("extension");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  invert = invert || model.invert;

  if ((inExtension && invert) || (!inExtension && !invert)) return null;

  return children || <ContentTypeRender path={path} items={model.item} />;
};
export default ExtensionView;
