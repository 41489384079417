import { useEffect } from "react";
import app from "nystem";

const GraphAdd = ({ model, view }) => {
  const { fieldType: type, fieldIdX, fieldIdY } = model;
  const x = view.getValue(fieldIdX);
  const y = view.getValue(fieldIdY);

  const { id } = view;

  useEffect(() => {
    // eslint-disable-next-line eqeqeq
    if ((!x && x != 0) || (!y && y != 0)) return;

    const graphEvent = (query) =>
      query.type === "add"
        ? {
            ...query,
            data: [...query.data, { type, y, x, id }],
          }
        : undefined;

    app().on("graphEvent", graphEvent);
    app().event("graphEvent", { data: [], type: "add" });

    return () => {
      app().off("graphEvent", graphEvent);
    };
  }, [view, model, y, x, id, type]);

  return null;
};
export default GraphAdd;
