import { useCallback } from "react";
import { Wrapper, ContentTypeRender } from "nystem-components";
import app from "nystem";

const byType = {
  "<": (v1, v2) => v1 < v2,
  ">": (v1, v2) => v1 > v2,
  "<=": (v1, v2) => v1 <= v2,
  ">=": (v1, v2) => v1 >= v2,
};

const ConditionalInt = ({ view, model, path }) => {
  const testCondition = useCallback(() => {
    const insertVal = (val) =>
      val.replace(/\{([a-z_.0-9]+)\}/gim, (str, p1) => {
        let val = "";
        if (p1 === "_language") val = app().settings.lang;
        else if (p1 === "_userid") val = app().session.user?._id;
        else if (p1 === "id") val = view.id;
        else if (p1 === "now") val = Date.now();
        else if (p1.indexOf("params.") === 0)
          val = view.params[p1.replace("params.", "")];
        else if (p1.indexOf("baseView.") !== 0)
          val = view.getValue(p1.replace("..", path));
        else {
          p1 = p1.replace("baseView.", "");
          if (p1.startsWith("baseView.")) {
            p1 = p1.replace("baseView.", "");
            val = view.baseView.baseView.getValue(p1.replace("..", path));
          } else val = view.baseView.getValue(p1.replace("..", path));
        }
        return val || "";
      });

    const { condition } = model;
    for (let i = 0; i < condition.length; i++) {
      const [field, test] = condition[i];
      const val = view.getValue(field);

      // eslint-disable-next-line prefer-const
      let [, type, testVal] = test.match(/([<>=]{1,2})(.+)/im) || [];
      testVal = insertVal(testVal);
      if (byType[type](parseFloat(val), parseFloat(testVal))) return true;
    }
    return false;
  }, [model, path, view]);

  if (testCondition())
    return (
      <Wrapper className={model.className}>
        <ContentTypeRender path={path} items={model.item} />
      </Wrapper>
    );
  return null;
};

export default ConditionalInt;
