import React, { useEffect, useState, useRef } from "react";
import app from "nystem";
import { Wrapper } from "nystem-components";

const GraphView = ({ model, view, value }) => {
  const { className, event = "graphEvent" } = model;

  const sizeRef = useRef();
  const [val, setVal] = useState();

  useEffect(() => {
    let timer = false;
    const setData = (query) => {
      if (query.type !== "add") return;
      if (timer) clearTimeout(timer);

      timer = setTimeout(() => {
        setVal(query);
      }, 0);
    };
    app().on(event, -100, setData);

    return () => {
      app().off(event, setData);
    };
  }, [event]);

  if (!val) return <Wrapper ref={sizeRef} className={className}></Wrapper>;
  const { getXLabel, getYLabel, data } = val;
  let bottom = data
    .map((item) => item.y)
    .reduce((res, y) => (res && y > res ? res : y), 0);
  bottom = bottom > 0 ? 0 : bottom;

  const top = data
    .map((item) => item.y)
    .reduce((res, y) => (y < res ? res : y), 0);

  const left = data
    .map((item) => item.x)
    .reduce((res, x) => (res && x > res ? res : x), 0);

  const right = data
    .map((item) => item.x)
    .reduce((res, x) => (x < res ? res : x), 0);

  const {
    top: y,
    left: x,
    width,
    height,
  } = sizeRef.current.getBoundingClientRect();

  const relX = right - Math.abs(left);
  const relY = bottom - Math.abs(top);

  const rel = data.map((item) => {
    let cx = ((item.x - left) / relX) * (width - 20) + 10;
    let cy = ((item.y - top) / relY) * (height - 20) + 10;
    if (isNaN(cy)) cy = 0;
    if (isNaN(cx)) cx = 0;

    return { cx, cy };
  });

  const doEvent = (type, index) => {
    app().event(event, { type, x, y, pos: rel[index], data: data[index] });
  };

  const zero = height - (bottom / relY) * (height - 20) - 10 || 0;
  return (
    <Wrapper
      ref={sizeRef}
      renderAs="svg"
      className={className}
      viewBox={`0 0 ${parseInt(width, 10)} ${parseInt(height, 10)}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <Wrapper
        renderAs="path"
        d={`M ${rel[0].cx} ${rel[0].cx} ${rel
          .map((item) => `L ${item.cx} ${item.cy}`)
          .join(" ")}`}
        fill="transparent"
        stroke="black"
      />
      <Wrapper
        renderAs="path"
        d={`M 10 10 L 10 ${height - 10}`}
        fill="transparent"
        stroke="black"
      />
      <Wrapper
        renderAs="path"
        d={`M 10 ${zero} L ${width - 10} ${zero}`}
        fill="transparent"
        stroke="black"
      />
      {rel.map((item, index) => (
        <Wrapper key={index} renderAs="circle" {...item} fill="black" r="3" />
      ))}
      {rel.map((pos, index) => (
        <Wrapper
          key={index}
          onClick={() => doEvent("click", index)}
          onMouseOver={() => doEvent("over", index)}
          onMouseOut={() => doEvent("out", index)}
          renderAs="circle"
          {...pos}
          fill="transparent"
          r="15"
        />
      ))}
    </Wrapper>
  );
};

export default GraphView;
