import React from "react";
import { IntInput, TextInput, Wrapper, BooleanInput } from "nystem-components";

const DynamicColorPicker = ({ model, value, setValue }) => (
  <>
    <Wrapper className="flex items-center">
      <TextInput
        model={{ ...model }}
        value={value}
        setValue={(val) => {
          if (val) {
            if (!val.startsWith("#")) val = `#${val}`;
            if (val.length !== 7) return;
          }
          setValue(val);
        }}
      />
      <Wrapper
        className="w-6 h-6 rounded-full ml-2"
        style={{ backgroundColor: value }}
      ></Wrapper>
    </Wrapper>
    <Wrapper renderAs="p" className="text-sm">
      {model.description}
    </Wrapper>
  </>
);

const IntDeviceInput = (props) => {
  const { deviceType } = props.model;

  if (deviceType === "switch")
    return (
      <BooleanInput
        {...props}
        setValue={(val) => props.setValue(val ? 1 : undefined)}
      />
    );

  if (deviceType === "light") {
    return (
      <DynamicColorPicker
        {...props}
        setValue={(val) => props.setValue(parseInt(val.substring(1), 16))}
        value={
          props.value ? `#${props.value.toString(16).padStart(6, "0")}` : ""
        }
      />
    );
  }

  return <IntInput {...props} />;
};
export default IntDeviceInput;
