import React from "react";
import { TextView } from "nystem-components";

const fanspeeds = [0, 14, 28, 42, 57, 71, 100];

const IntFanspeed = ({ value, view, model }) => {
  if (!value) value = 0;
  const pos = fanspeeds.indexOf(value);

  return (
    <TextView model={model} view={view} value={pos === -1 ? value : pos} />
  );
};
export default IntFanspeed;
