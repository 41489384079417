import React, { useEffect, useState } from "react";
import { Wrapper, ContentTypeRender } from "nystem-components";
import app from "nystem";

const ReferenceInsertToModel = ({ model, value, view, path }) => {
  const { className, map, item, source } = model;
  const [add, setAdd] = useState(false);

  useEffect(() => {
    let mounted = true;

    const id = model.pickIdFromParam
      ? view.params[model.pickIdFromParam]
      : value;

    app()
      .database[source].get({ id })
      .then(({ data }) => {
        if (mounted)
          setAdd(
            map.reduce((result, [from, to, fallback]) => {
              result[to] = (data && data[from]) || fallback;
              return result;
            }, {})
          );
      });

    return () => {
      mounted = false;
    };
  }, [map, model.pickIdFromParam, source, value, view.params]);

  if (!add) return null;

  return (
    <Wrapper className={className}>
      <ContentTypeRender
        items={item.map((item) => ({ ...item, ...add }))}
        path={path}
      />
    </Wrapper>
  );
};

export default ReferenceInsertToModel;
