/* eslint-disable no-new */
import { Wrapper, Icon } from "nystem-components";
import app from "nystem";
import { useEffect, useState } from "react";

const MapInfo = ({ model, value, view }) => {
  const [val, setVal] = useState();

  useEffect(() => {
    let val = value;
    const setAt = ({ id, value }) => {
      if (id !== model.id) return;
      val = value[id];
      fetchAltitude();
    };
    view.on("change", setAt);

    const fetchAltitude = async () => {
      try {
        const [lng2, lat2] = val.coordinate;
        const response = await fetch(
          `https://api.open-elevation.com/api/v1/lookup?locations=${lat2},${lng2}`
        );

        const { results } = await response.json();
        setVal(results[0].elevation);
        // eslint-disable-next-line no-empty
      } catch (e) {}
    };
    fetchAltitude();

    const distType = ["distance", "direction"].includes(model.field);
    const update = (data) => {
      if (distType) {
        const { latitude: lat1, longitude: lng1 } = data;
        try {
          const [lng2, lat2] = val.coordinate;
          setVal(app().mapAzimuth(lat1, lng1, lat2, lng2).distance);
          // eslint-disable-next-line no-empty
        } catch (e) {}
      } else setVal(data[model.field]);
    };

    if (model.field !== "coordinatealtitude") {
      app().on("mapsDevicePos", update);
      app().event("mapsDevicePos");
    }

    return () => {
      app().off("mapsDevicePos", update);
      view.off("change", setAt);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model]);

  if (model.field === "compass")
    return (
      <Wrapper
        renderAs="img"
        src="/image/compass.svg"
        className={model.className}
        style={{ transform: `rotate(${val + 180}deg)` }}
      />
    );

  if (model.kilo)
    return (
      <Wrapper className={model.className}>
        {val
          ? parseFloat(val / 1000).toFixed(model.decimal || 0)
          : model.fallback}
      </Wrapper>
    );

  return (
    <Wrapper className={model.className}>
      {val ? parseFloat(val).toFixed(model.decimal || 0) : model.fallback}
    </Wrapper>
  );
};

export default MapInfo;
