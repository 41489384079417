const getDistance = ([lat1, lon1], [lat2, lon2], unit = "K") => {
  const radlat1 = (Math.PI * lat1) / 180;
  const radlat2 = (Math.PI * lat2) / 180;

  const theta = lon1 - lon2;
  const radtheta = (Math.PI * theta) / 180;

  let dist =
    Math.sin(radlat1) * Math.sin(radlat2) +
    Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);

  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;

  if (unit === "K") dist *= 1.609344;
  if (unit === "N") dist *= 0.8684;

  return dist;
};

const FloatMapDistance = ({ model, view, setValue, value }) => {
  const { fieldId, latId, longId, fieldType = "distance", distanceId } = model;

  const distance = getDistance(
    [view.getValue(latId), view.getValue(longId)],
    [view.baseView.getValue(latId), view.baseView.getValue(longId)]
  );

  const byType = {
    tripDistance: () =>
      view.baseView.getValue(distanceId) -
      view.baseView.baseView.getValue(distanceId) +
      distance,
    distance: () => distance.toFixed(1),
    energy: () => {
      const from = view.getValue(fieldId);
      const to = view.baseView.getValue(fieldId);

      if (!from || !to) return undefined;

      return ((from - to) / distance) * 1000;
    },
    speed: () =>
      distance /
      ((view.getValue(fieldId) - view.baseView.getValue(fieldId)) /
        (1000 * 60 * 60)),
  };
  const newVal = byType[fieldType]();
  if (value !== newVal) setValue(newVal);

  return null;
};
export default FloatMapDistance;
