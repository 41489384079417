import React from "react";
import app from "nystem";
import { Wrapper } from "nystem-components";

const getDiff = (from, to) => {
  if (!from || !to) return 0;

  const delta = parseInt(to, 10) - parseInt(from, 10);
  let out = " ";
  let minit = delta / 1000 / 60;

  const days = Math.trunc(minit / 60 / 24);
  if (days) out = `${days}d `;
  minit -= days * 60 * 24;

  const hours = Math.trunc(minit / 60);
  if (hours) out += `${hours}h `;
  minit -= hours * 60;

  if (minit) out += `${Math.trunc(minit)}m`;
  return out;
};

const DateDiff = ({ model, view, value }) => {
  const { className, renderAs, id } = model;

  if (!value) return null;

  const result = getDiff(value, view.baseView.value[id]);

  return (
    <Wrapper renderAs={renderAs} className={className}>
      {app().t(value ? result : "")}
    </Wrapper>
  );
};

export default DateDiff;
