import { useEffect } from "react";

const sortFuncInt = (key, reverse) => {
  const up = reverse ? 1 : -1;
  const down = reverse ? -1 : 1;

  return (a, b) => {
    const x = a[key];
    const y = b[key];

    if (x === undefined) return y === undefined ? 0 : down;
    if (y === undefined) return up;
    return x < y ? up : x > y ? down : 0;
  };
};

const getDistance = ([lat1, lon1], [lat2, lon2], unit = "K") => {
  const radlat1 = (Math.PI * lat1) / 180;
  const radlat2 = (Math.PI * lat2) / 180;

  const theta = lon1 - lon2;
  const radtheta = (Math.PI * theta) / 180;

  let dist =
    Math.sin(radlat1) * Math.sin(radlat2) +
    Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);

  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;

  if (unit === "K") dist *= 1.609344;
  if (unit === "N") dist *= 0.8684;

  return dist;
};

const getPos = ({ lat, long }) => {
  lat = parseFloat(lat);
  long = parseFloat(long);

  return [lat, long];
};

const DatabaseSortByDistance = ({ view, children, model }) => {
  useEffect(() => {
    const sortByDistance = (query) => {
      let { data } = query;

      if (!data) return;

      const ref = getPos({
        lat: view.baseView.value[model.latId],
        long: view.baseView.value[model.longId],
      });
      data = [...data]
        .filter((item) => item._id !== view.value._id)
        .map((item) => ({
          ...item,
          distance: getDistance(ref, getPos(item)),
        }));

      data.sort(sortFuncInt("distance"));

      if (model.count) data = data.slice(0, model.count);

      query.data = data.map((item) => ({ ...item, distance: undefined }));
    };
    view.on("search", sortByDistance);

    const onChange = () => {
      view.event("setSearch");
    };
    view.on("change", onChange);
    return () => {
      view.off("search", sortByDistance);
      view.off("change", onChange);
    };
  }, [model.count, model.latId, model.longId, view]);

  return children || null;
};

export default DatabaseSortByDistance;
