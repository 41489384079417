/* eslint-disable no-new */
import { Wrapper, Icon } from "nystem-components";
import app from "nystem";
import { useEffect, useState } from "react";

let compass = 0;

const MapWeather = ({ model, value, view }) => {
  const [val, setVal] = useState();

  useEffect(() => {
    let weatherData = {};
    const getValue = (path, data) =>
      path
        ? path.split(".").reduce((val, key) => val && val[key], data)
        : value;

    const update = () => {
      let current =
        model.show === "weather.0.description"
          ? weatherData.weather?.map((item) => item.description).join(", ")
          : getValue(model.show, weatherData);
      if (model.relative && model.show === "wind.deg") current -= compass;
      setVal(current);
    };
    const weatherUpdate = ({ weatherData: data }) => {
      weatherData = data;
      update();
    };
    view.on(`weatherUpdate`, weatherUpdate);

    const mapsDevicePos = (data) => {
      ({ compass = 0 } = data);
      update();
    };
    if (model.relative) {
      app().on("mapsDevicePos", mapsDevicePos);
      app().event("mapsDevicePos");
    }

    return () => {
      app().off("mapsDevicePos", mapsDevicePos);
      view.off("weatherUpdate", mapsDevicePos);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model, view]);

  if (model.show === "wind.deg")
    return <Icon icon="down-long" className={model.className} deg={val} />;

  return <Wrapper className={model.className}>{val || model.fallback}</Wrapper>;
};

export default MapWeather;
